import './App.css';
import Tiles from './Tiles';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Tiles />
      </header>
    </div>
  );
}

export default App;
