import React from 'react';
import {Grid, Card, CardContent, Typography} from '@mui/material';

const Tiles = () => {
    const tilesData = [
        {
            icon: 'cb.png',
            link: 'https://chimeramultimedia.com/chat',
            description: 'A ChatBojtár egy mesterséges intelligencia jogi asszisztens, amely fejlett technológiát használ a dokumentumkezelés és a munkafolyamatok egyszerűsítésére. Támogatja a dokumentumkeresést, az interaktív beszélgetéseket és a forráshivatkozásokat. Az alapvetőtől a testreszabott vállalati megoldásokig terjedő lehetőségekkel a ChatBojtár növeli a hatékonyságot, és web chatbotként is integrálható, így hatékony eszköz a vállalati teljesítmény javítására.'
        },
        {
            icon: 'opp.png',
            link: 'https://chimeramultimedia.com/opponent',
            description: 'A ChatBojtár ezen összetevője kritikai értékelőként vagy ellenfélként működik. Úgy van programozva, hogy elemezze és megkérdőjelezze a felhasználók által szolgáltatott információkat, szimulálva a jogi ellenállást. Fő funkciója a feltételezések, érvek és bizonyítékok megkérdőjelezése, ezáltal segítve a felhasználókat ügyeik finomításában és alaposabban felkészülve a jogi eljárások során felmerülő esetleges ellenérvekre.'
        },
        {
            icon: 'prep.png',
            link: 'https://chimeramultimedia.com/preparator',
            description: 'Az Opponens Bojtárral együtt dolgozó Preparátor Bojtár a jogi ügyek aprólékos előkészítésére összpontosít. Segíti a felhasználókat a releváns jogi információk, dokumentumok és hivatkozások összegyűjtésével, rendszerezésével és bemutatásával. Ennek a botnak az a célja, hogy a felhasználók jól felkészültek legyenek, átfogó háttérrel és szilárd alapokkal rendelkezzenek ügyeikhez vagy jogi érveikhez.'
        },
    ];

    return (
        <div style={{}}>
            <Grid container spacing={4} justifyContent="center">
                {tilesData.map((tile, index) => (
                    <Grid item key={index} xs={12} sm={6} md={3}>
                        <Card
                            onClick={() => window.location.href = tile.link}
                            elevation={3}
                            style={{
                                padding: '10px',
                                backgroundColor: 'black',
                                color: 'white',
                                cursor: 'pointer',
                                borderRadius: 35
                            }}
                        >
                            <CardContent>
                                <img src={process.env.PUBLIC_URL + `/${tile.icon}`} alt={`Icon ${index + 1}`}
                                     style={{width: '300px', height: '300px', marginBottom: '10px'}}/>
                                <Typography variant="body2" component="p">
                                    {tile.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default Tiles;
